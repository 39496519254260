//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import jybWins from "@/assets/downloads/jyb_wins.png";
import qgbMac from "@/assets/downloads/qgb_mac.png";
import qgbWins from "@/assets/downloads/qgb_wins.png";
import android from "@/assets/sidebar/android.png";
import ios from "@/assets/sidebar/ios.png";
import wuliuapp from "@/assets/downloads/wuliuapp.png";
import wuliuWx from "@/assets/downloads/wuliuWx.jpg";
import jybAndroid from "@/assets/sidebar/jyb-android.png";
import jybIos from "@/assets/sidebar/jyb-ios.png";
import jingyingbangDown from "@/assets/sidebar/jingyingbang.png";
import qiguanbang from "@/assets/sidebar/qiguanbang.png";
import jingyingbangWuliu from "@/assets/sidebar/jingyingbang-wuliu.png";
import jingyingbangSc from "@/assets/sidebar/jingyingbang-sc.png";
import jingyingbangVip from "@/assets/sidebar/jingyingbang-vip.png";
import jingyingbangZc from "@/assets/sidebar/jingyingbang-zc.png";
import jingyingbangZl from "@/assets/sidebar/jingyingbang-zl.png";
import jingyingbangGd from "@/assets/sidebar/jingyingbang-gd.png";
import newQilianduo from "@/assets/sidebar/newQilianduo.png";

import wechatSmall from "@/assets/public/xiaochengxu_max_remark.png";
import { getQgbAndroid, getqldAndroid } from "@/api/apiDownload";
import { getOS, getDownLoad } from "@/util/util.js";

import QRCode from "qrcodejs2";
import env from "@/../env.js";
let isProduction = env[process.env.environment].MODE === "production";
export default {
  data() {
    console.log('isProduction', env[process.env.environment].MODE)
    return {
      jygConf: [
        {
          name: "经营帮",
          downloadCoed: jingyingbangDown,
          type: "qrcode"
        },
        {
          name: "微信小程序",
          downloadCoed: wechatSmall,
          type: "qrcode"
        }
      ],
      qgbConf: [
        {
          name: "企管帮",
          downloadCoed: qiguanbang,
          type: "qrcode"
        }
      ],
      // wuliuConf: [],
      qldConf: [
        {
          name: "安卓APP",
          downloadCoed: newQilianduo,
          type: "qrcode"
        }
      ],
      jybConf: [
        {
          name: "经营商城",
          type: "qrcode",
          downloadCoed: jingyingbangSc
        },
        {
          name: "经营会员",
          type: "qrcode",
          downloadCoed: jingyingbangVip
        },
        {
          name: "经营招采",
          type: "qrcode",
          downloadCoed: jingyingbangZc
        },
        {
          name: "经营租赁",
          type: "qrcode",
          downloadCoed: jingyingbangZl
        },
        {
          name: "工人工队",
          type: "qrcode",
          downloadCoed: jingyingbangGd
        },
        {
          name: "经营物流",
          downloadCoed: jingyingbangWuliu,
          type: "qrcode"
        },
        {
          name: "微信小程序",
          downloadCoed: wuliuWx,
          type: "qrcode"
        }
      ],
      jybPcConf: [
        {
          name: "Windows 版下载",
          downloadCoed: qgbWins, // jybWins
          type: "link",
          downLink: (function() {
            return (
              getDownLoad(getOS()) ||
              "https://sgb-manager-client.oss-cn-beijing.aliyuncs.com/jyb/经营帮客户端.exe"
            );
          })(),
          fineName: "经营帮客户端"
        }
      ],
      qgbPcConf: [
        {
          name: "Windows 版下载",
          downloadCoed: qgbWins,
          type: "link",
          downLink:
            "https://zjkj-sgb-manager-client.obs.cn-east-3.myhuaweicloud.com/builder/%E4%BC%81%E7%AE%A1%E5%B8%AE%E5%AE%A2%E6%88%B7%E7%AB%AF%E5%AE%89%E8%A3%85%E5%8C%85.exe",
          fineName: "企管帮客户端安装包"
        },
        {
          name: "Mac 版下载",
          downloadCoed: qgbMac,
          type: "link",
          downLink:
            "https://zjkj-sgb-manager-client.obs.cn-east-3.myhuaweicloud.com/builder/%E4%BC%81%E7%AE%A1%E5%B8%AE%E5%AE%A2%E6%88%B7%E7%AB%AF%E5%AE%89%E8%A3%85%E5%8C%85_MAC.dmg",
          fineName: "企管帮客户端安装包",
          downLoadType: "mac"
        }
      ],
      qldPcConf: [
        {
          name: "Windows 版下载",
          downloadCoed: qgbWins,
          type: "link",
          downLink: isProduction
            ? "https://gateway.jingyingbang.com/qld-java-server/management/SoftwareInventory/latest/windows_x86_64"
            : "https://api.jybtech.cn/qld-java-server/management/SoftwareInventory/latest/windows_x86_64",

          fineName: "企联多客户端安装包"
        }
      ],
      // 招采正式安装下载地址 https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/ZhaoCai/Prod/ZJKJ.JYB-ZC.Setup.exe
      // 招采测试安装下载地址 https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/ZhaoCai/Test/ZJKJ.JYB-ZC.Setup.exe

      morePcConf: [
        {
          name: "Windows 版下载",
          prodName: "招采经营",
          downloadCoed: qgbWins,
          type: "link",
          downLink: isProduction
            ? "https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/ZhaoCai/Prod/ZJKJ.JYB-ZC.Setup.exe"
            : "https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/ZhaoCai/Test/ZJKJ.JYB-ZC.Setup.exe",

          fineName: "招采客户端安装包"
        },
        // 会员正式安装包下载地址:https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/%20HuiYuan/Prod/ZJKJ.JYB.HY.Setup.exe
        // 会员测试安装包下载地址：https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/%20HuiYuan/Test/ZJKJ.JYB.HY.Setup.exe
        {
          name: "Windows 版下载",
          prodName: "经营会员",
          downloadCoed: qgbWins,
          type: "link",
          downLink: isProduction
            ? "https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/%20HuiYuan/Prod/ZJKJ.JYB.HY.Setup.exe"
            : "https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/%20HuiYuan/Test/ZJKJ.JYB.HY.Setup.exe",
          fineName: "会员客户端安装包"
        },
        //物流正式安装包下载地址： https://jyb-wuliu.obs.cn-east-3.myhuaweicloud.com/client-setup/Prod/ZJKJ.JYB.WL.Setup.exe
        // 物流测试安装包下载地址： https://jyb-wuliu.obs.cn-east-3.myhuaweicloud.com/client-setup/Test/ZJKJ.JYB.WL.Setup.exe
        {
          name: "Windows 版下载",
          prodName: "经营物流",
          downloadCoed: qgbWins,
          type: "link",
          downLink: isProduction
            ? "https://jyb-wuliu.obs.cn-east-3.myhuaweicloud.com/client-setup/Prod/ZJKJ.JYB.WL.Setup.exe"
            : "https://jyb-wuliu.obs.cn-east-3.myhuaweicloud.com/client-setup/Test/ZJKJ.JYB.WL.Setup.exe",
          fineName: "物流客户端安装包"
        },
        //商城正式安装包下载地址： https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/ShangCheng/Prod/ZJKJ.JYB.SC.Setup.exe
        //商城测试安装包下载地址： https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/ShangCheng/Test/ZJKJ.JYB.SC.Setup.exe
        {
          name: "Windows 版下载",
          prodName: "经营产业链",
          downloadCoed: qgbWins,
          type: "link",
          downLink: isProduction
            ? "https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/ShangCheng/Prod/ZJKJ.JYB.SC.Setup.exe"
            : "https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/ShangCheng/Test/ZJKJ.JYB.SC.Setup.exe",
          fineName: "商城客户端安装包"
        },
        //工队正式安装包下载地址： https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/GongDui/Prod/%E7%BB%8F%E8%90%A5%E5%B8%AE%E5%B7%A5%E9%98%9F.exe
        //工队测试安装包下载地址： https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/GongDui/Test/%E7%BB%8F%E8%90%A5%E5%B8%AE%E5%B7%A5%E9%98%9F.exe'
         {
          name: "Windows 版下载",
          prodName: "工人工队",
          downloadCoed: qgbWins,
          type: "link",
          downLink: isProduction
            ? "https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/GongDui/Prod/%E7%BB%8F%E8%90%A5%E5%B8%AE%E5%B7%A5%E9%98%9F.exe"
            : "https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/GongDui/Test/%E7%BB%8F%E8%90%A5%E5%B8%AE%E5%B7%A5%E9%98%9F.exe",
          fineName: "工队客户端安装包"
        },
        {
          name: "Windows 版下载",
          prodName: "经营租赁",
          downloadCoed: qgbWins,
          type: "link",
          downLink: isProduction
            ? "https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/ZuLin/Prod/%E7%BB%8F%E8%90%A5%E5%B8%AE%E7%A7%9F%E8%B5%81.exe"
            : "https://jingyingbang-client.obs.cn-east-3.myhuaweicloud.com/ZuLin/Prod/%E7%BB%8F%E8%90%A5%E5%B8%AE%E7%A7%9F%E8%B5%81.exe",
          fineName: "租赁客户端安装包"
        }
      ]
    };
  },
  props: {
    showDownLoad: {
      type: Boolean,
      default: false
    },
    loadType: {
      type: String,
      default: "app"
    }
  },
  methods: {
    handleClose() {
      this.$emit("close", false);
    },
    creatQrCode($el, content) {
      // console.log("this.$refs.qrCodeUrl", this.$refs["qgbqrCodeUrl0"]);
      var qrcode = new QRCode($el, {
        text: content,
        width: 110,
        height: 110,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H
      });
      // console.log("qrcode", qrcode);
    },
    downLoadApp(item) {
      if (item.type === "qrcode") {
        return false;
      } else {
        if (item.downLink) {
          var a = document.createElement("a");
          var fileName = item.fileName;
          a.download = fileName;
          a.href = item.downLink;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    },
    async initQgbConf() {
      let res = await getQgbAndroid();
      let download_url = res.data.data.download_url;
      this.creatQrCode(this.$refs["qgbqrCodeUrl0"][0], download_url);
      this.creatQrCode(
        this.$refs["qgbqrCodeUrl1"][0],
        "https://apps.apple.com/us/app/qi-guan-bang/id1508999712?l=zh&ls=1"
      );
    },
    async initQldConf() {
      let res = await getqldAndroid();
      // console.log("initQldConf", res);
      if (res.data.state === "ok") {
        let appQRlist = res.data.data.apps.list;
        appQRlist.sort(version_code => {
          return function(a, b) {
            return b[version_code] - a[version_code];
          };
        });
        let download_url = appQRlist[0].download_url;
        // console.log("download_url", download_url);
        this.creatQrCode(this.$refs["qldqrCodeUrl0"][0], download_url);
      }
    },
    setItemRrl(item, type) {
      if (item.type == "link") {
        if (type === "in") {
          item.downloadCoed = jybWins;
        } else {
          if (item.downLoadType && item.downLoadType == "mac") {
            item.downloadCoed = qgbMac;
          } else {
            item.downloadCoed = qgbWins;
          }
        }
      }
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initQgbConf();
      this.initQldConf();
    });
  }
};
